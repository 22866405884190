<template>
  <div class="data_show">
    <!-- 头部信息 -->
    <div class="active_title">
      <div class="tt_left">
        <h2>{{ row.name }}活动</h2>
        <p>
          活动时间：<span>{{ row.startTime }}~{{ row.endTime }}</span>
        </p>
      </div>
      <div class="tt_right">
        <span class="rest_time">数据更新于 {{ row.startTime }}</span>
        <el-button size="small" @click="goBack"> 返回</el-button>
      </div>
    </div>
    <div class="search">
      <h5>数据统计</h5>
      <el-date-picker
        v-model="dataTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
        @change="dataTimeChange"
      >
      </el-date-picker>
    </div>
    <!-- 数据汇总 -->
    <div class="data_list">
      <div class="data_item">
        <span>销售额 （元）</span>
        <p class="money">{{ activeStatistics.salesVolume }}</p>
      </div>
      <div class="data_item">
        <span>交易额 （元）</span>
        <p class="money">{{ activeStatistics.tradeAmount }}</p>
      </div>
      <div class="data_item">
        <span>交易订单量（单）</span>
        <p class="money">{{ activeStatistics.tradeOrderNum }}</p>
      </div>
      <div class="data_item">
        <span>交易用户数（人）</span>
        <p class="money">{{ activeStatistics.tradeUserNum }}</p>
      </div>
      <div class="data_item">
        <span>客单价（元）</span>
        <p class="money">{{ activeStatistics.perCustomer || 0 }}</p>
      </div>
      <div class="data_item">
        <span>退款金额（元）</span>
        <p class="money">{{ activeStatistics.refundPrice }}</p>
      </div>
      <div class="data_item">
        <span>退款订单量（单）</span>
        <p class="money">{{ activeStatistics.refundOrderNum }}</p>
      </div>
      <div class="data_item">
        <span>店铺营收（元）</span>
        <p class="money">{{ activeStatistics.storeRevenue }}</p>
      </div>
      <div class="data_item">
        <span>商品活动数（个）</span>
        <p class="money">{{ row.inventory }}</p>
      </div>
    </div>
    <!-- 图表展示 -->
    <el-row :gutter="20" class="el-row-box">
      <!-- 交易额图表 -->
      <el-col class="el-col-m" :span="gutterNum">
        <div class="grid-content bg-purple">
          <el-radio-group v-model="amountTime" class="select">
            <el-radio-button :label="'日期'"></el-radio-button>
            <el-radio-button :label="'时刻'">
              <el-dropdown @command="handleCommandAmount" size="small">
                <span class="el-dropdown-link"> 时刻 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in amountDateList"
                    :key="item.id"
                    :command="item"
                    >{{ item }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-radio-button>
          </el-radio-group>
          <echartsLine
            :echartsData="amountData"
            :height="'348px'"
          ></echartsLine>
        </div>
      </el-col>
      <!-- 订单图表 -->
      <el-col class="el-col-m" :span="gutterNum">
        <div class="grid-content bg-purple">
          <el-radio-group v-model="orderTime" class="select">
            <el-radio-button :label="'日期'"></el-radio-button>
            <el-radio-button :label="'时刻'">
              <el-dropdown @command="handleCommandOrder" size="small">
                <span class="el-dropdown-link"> 时刻 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in orderDateList"
                    :key="item.id"
                    :command="item"
                    >{{ item }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-radio-button>
          </el-radio-group>
          <echartsLine :echartsData="orderData" :height="'348px'"></echartsLine>
        </div>
      </el-col>
      <!-- 用户图表 -->
      <el-col class="el-col-m" :span="gutterNum">
        <div class="grid-content bg-purple">
          <el-radio-group v-model="userTime" class="select">
            <el-radio-button :label="'日期'"></el-radio-button>
            <el-radio-button :label="'时刻'">
              <el-dropdown @command="handleCommandUser" size="small">
                <span class="el-dropdown-link"> 时刻 </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in userDateList"
                    :key="item.id"
                    :command="item"
                    >{{ item }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-radio-button>
          </el-radio-group>
          <echartsLine :echartsData="userData" :height="'348px'"></echartsLine>
        </div>
      </el-col>
      <el-col class="el-col-m" :span="gutterNum">
        <div class="grid-content bg-purple">
          <el-card class="box-card" style="height: 388px">
            <h3>商品销量排名</h3>
            <el-table :data="tableData" style="width: 100%; margin-top: 20px">
              <el-table-column type="index" label="排名" width="80">
              </el-table-column>
              <el-table-column
                prop="name"
                label="商品名称"
                show-overflow-tooltip
                width="180"
              >
              </el-table-column>
              <el-table-column prop="classification" label="商品分类">
              </el-table-column>
              <el-table-column prop="transactionUserNum" label="成交人数">
              </el-table-column>
              <el-table-column prop="transactionOrderNum" label="成交订单量">
              </el-table-column>
              <el-table-column prop="salesVolume" label="销售额">
              </el-table-column>
            </el-table>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echartsLine from "./components/echarts.vue";
export default {
  name: "dataShow",
  components: {
    echartsLine,
  },
  data() {
    return {
      params: {
        endTime: "",
        startTime: "",
      }, //获取统计数据参数
      row: {},
      activeStatistics: {}, //统计数据对象
      tableData: [], //表格数据
      dataTime: "", //日期范围
      // type: "",
      //   表格数据
      gutterNum: 12,
      amountData: { title: "交易额趋势" }, //交易额折线图数据
      orderData: { title: "交易订单趋势" }, //交易额折线图数据
      userData: { title: "交易用户趋势" }, //交易额折线图数据
      amountTime: "日期", //交易额折线图时间类型
      orderTime: "日期", //交易额折线图时间类型
      userTime: "日期", //交易额折线图时间类型
      amountDate: "", //交易额折线图时刻
      orderDate: "", //交易额折线图时刻
      userDate: "", //交易额折线图时刻
      amountDateList: [], //交易额折线图时刻列表
      orderDateList: [], //交易额折线图时刻列表
      userDateList: [], //交易额折线图时刻列表
    };
  },
  watch: {
    amountTime: {
      handler(newVal) {
        if (newVal == "日期") {
          this.amountDate = "";
          this.getLineChartAmount();
        }
      },
    },
    orderTime: {
      handler(newVal) {
        if (newVal == "日期") {
          this.orderDate = "";
          this.getLineChartOrder();
        }
      },
    },
    userTime: {
      handler(newVal) {
        if (newVal == "日期") {
          this.userDate = "";
          this.getLineChartUser();
        }
      },
    },
  },
  methods: {
    dataTimeChange(e) {
      this.dataTime = e;
      this.params.startTime = e ? e[0] : null;
      this.params.endTime = e ? e[1] : null;
      this.queryDataStatistics();
    },
    handleCommandAmount(command) {
      this.amountDate = command;
      this.getLineChartAmount();
    },
    handleCommandOrder(command) {
      console.log(command);
      this.orderDate = command;
      this.getLineChartOrder();
    },
    handleCommandUser(command) {
      this.userDate = command;
      this.getLineChartUser();
    },
    goBack() {
      this.$router.go(-1);
    },

    // 交易额数据获取
    async getLineChartAmount() {
      const res = await this.$api.active.reqLineChartAmount({
        activityId: this.row.activityId,
        shopId: this.row.shopId,
        type: this.amountTime == "日期" ? 1 : 2,
        date: this.amountDate,
      });
      if (res.code == 200) {
        this.amountData = res.data || {};
        if (this.amountTime == "日期") {
          this.amountDateList = res.data.x;
        }
      } else {
        this.amountData = {};
        this.$message.error("交易额数据获取失败");
      }
    },
    async getLineChartOrder() {
      const res = await this.$api.active.reqLineChartOrder({
        activityId: this.row.activityId,
        shopId: this.row.shopId,
        type: this.orderTime == "日期" ? 1 : 2,
        date: this.orderDate,
      });
      if (res.code == 200) {
        this.orderData = res.data || {};
        if (this.orderTime == "日期") {
          this.orderDateList = res.data.x;
        }
      } else {
        this.orderData = {};
        this.$message.error("订单数据获取失败");
      }
    },
    async getLineChartUser() {
      const res = await this.$api.active.reqLineChartUser({
        activityId: this.row.activityId,
        shopId: this.row.shopId,
        type: this.userTime == "日期" ? 1 : 2,
        date: this.userDate,
      });
      if (res.code == 200) {
        this.userData = res.data || {};
        if (this.userTime == "日期") {
          this.userDateList = res.data.x;
        }
      } else {
        this.userData = {};
        this.$message.error("用户数据获取失败");
      }
    },
    getClientWidth() {
      // 获取屏幕宽度按动态分配一行几个图
      let clientW = document.body.clientWidth;
      if (clientW >= 1680) {
        this.gutterNum = 12;
      } else if (clientW >= 1200) {
        this.gutterNum = 12;
      } else if (clientW < 1200) {
        this.gutterNum = 24;
      }
    },
    // 获取统计数据
    queryTableList() {
      this.$api.active
        .reqRankList({
          activityId: this.row.activityId,
          shopId: this.row.shopId,
          rankNum: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data || [];
          } else {
            this.tableData = {};
            this.$message.error("排名数据获取失败");
          }
        });
    },
    // 获取统计数据
    queryDataStatistics() {
      this.$api.active
        .reqDataStatistics({
          ...this.params,
          activityId: this.row.activityId,
          shopId: this.row.shopId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.activeStatistics = res.data || [];
          } else {
            this.activeStatistics = {};
            this.$message.error("数据获取失败");
          }
        });
    },
  },
  created() {
    this.row = JSON.parse(this.$route.query.row);
    this.getClientWidth();
    this.queryDataStatistics();
    this.queryTableList();
    this.getLineChartAmount();
    this.getLineChartOrder();
    this.getLineChartUser();
  },

  mounted() {
    // 注册事件，进行监听
    window.addEventListener("resize", this.getClientWidth);
  },            
  beforeDestroy() {
    window.removeEventListener("resize", this.getClientWidth);
  },
};
</script>

<style scoped lang="less">
.active_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  p {
    padding: 10px 0;
  }
  .rest_time {
    color: #999;
    padding-right: 15px;
  }
}
.search {
  display: flex;
  align-items: center;
  padding: 20px 0;

  p {
    padding: 20px 0;
  }
}
.bg-purple {
  position: relative;
}

.select {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 9999;
}
.data_list {
  display: flex;
  flex-wrap: wrap;
  .data_item {
    box-sizing: border-box;
    height: 135px;
    width: 19.5%;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 2px 3px 4px rgba(0, 0, 0, 0.05);
    margin-right: 8px;
    margin-bottom: 15px;
    span {
      color: #666;
    }
    .money {
      padding: 15px 0;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
h5 {
  color: #666;
  padding: 0 5px;
  margin-right: 15px;
  border-left: 5px solid #31aefc;
}
</style>
